<template>
  <div class="p-grid dashboard p-jc-center">
    <info-card
      :key="`infoCardLoading-${loading}`"
      :details="details"
      :loading="loading"
    />
    <new-matches
      v-if="authUser.is_approved && !canViewAdminStats"
      :matches="matches"
      :loading="loading"
    />
    <div
      v-else-if="!authUser.is_approved"
      class="
        unapproved-user
        p-mx-5 p-d-flex p-flex-column p-jc-center p-ai-center
      "
    >
      <div class="p-my-3">
        <img class="unapproved-user-img" src="/awaiting.png" />
      </div>
      <p class="unapproved-text">
        To get the benefits of the app, please complete your profile and wait
        for the admin's approval
      </p>
    </div>
    <stats
      v-else-if="canViewAdminStats"
      :approval-stats="details.approval_stats"
      :males-count="details.male_count"
      :females-count="details.female_count"
      :males-age="details.male_age_group"
      :females-age="details.female_age_group"
      class="stats"
    />
  </div>
</template>

<script>
import InfoCard from '@/components/dashboard/InfoCard.vue';
import NewMatches from '@/components/dashboard/NewMatches.vue';
import Stats from '@/components/Stats.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    InfoCard,
    NewMatches,
    Stats,
  },
  data() {
    return {
      details: {},
      matches: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['authUser', 'canViewAdminStats']),
  },
  created() {
    this.fetchMatches();
  },
  methods: {
    fetchMatches() {
      this.loading = true;
      let that = this;
      if (this.canViewAdminStats) {
        this.$http.get('/users/admin_stats').then((res) => {
          that.details = res.data;
          that.loading = false;
        });
      } else {
        this.$http.get(`/users/home_page`).then((res) => {
          that.details = res.data;
          that.matches = res.data.new_matches.data?.splice(0, 6);
          that.loading = false;
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.unapproved-text {
  font-size: 1.3rem;
  color: $blurPinkColor;
  text-align: center;
}
.unapproved-user {
  margin-top: 5rem;
}
.stats {
  width: 98%;
  margin: 1rem 0;
}
</style>
