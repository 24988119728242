<template>
  <div class="p-grid dashboard">
    <div class="card">
      <div class="p-d-flex p-jc-between p-ai-center">
        <h5 class="p-m-0 c-tc-title">New Matches</h5>
        <router-link v-show="matches.length" exact to="/matches/allmatches">
          <p class="p-pr-5 view-all">View all</p>
        </router-link>
      </div>

      <DataView v-if="loading" :value="loadingArray" layout="grid">
        <template #grid="">
          <div class="p-col-12 p-sm-6 p-md-4 p-lg-2 img-col p-pb-3">
            <div class="gallery__item gallery__item--1 p-mt-3">
              <div class="loading p-grid">
                <div class="p-d-flex p-ai-center p-col-12 p-jc-center">
                  <Skeleton height="12rem" width="100%" />
                </div>
              </div>
              <p class="p-col-12 p-mb-3 p-p-0">
                <Skeleton height="1rem" width="60%" class="p-mb-1" />
                <Skeleton height="1rem" width="30%" />
              </p>
            </div>
          </div>
        </template>
      </DataView>

      <DataView v-else :value="matches" layout="grid">
        <template #empty>
          <div class="no-new-matches">
            <img
              :src="`${
                $store.getters.authUser.gender === 'male'
                  ? 'bride.png'
                  : 'groom.png'
              }`"
              class="no-new-matches-img col-md-12"
            />
            <p class="no-new-matches-text p-ma-1">No new matches found</p>
          </div>
        </template>

        <template #grid="match">
          <div class="p-col-12 p-sm-6 p-md-4 p-lg-2 img-col p-pb-3">
            <div class="gallery__item gallery__item--1 p-mt-3">
              <div
                v-if="match.data.attributes.gender == 'female'"
                class="user-img-card"
                :style="{
                  backgroundImage: `url(${match.data.attributes.profile_photo_thumb_url})`,
                }"
                :class="
                  match.data.attributes.profile_photo_thumb_url
                    ? 'user-img-back'
                    : null
                "
              >
                <img
                  :src="
                    match.data.attributes.profile_photo_thumb_url ||
                    '/bride.png'
                  "
                  class="gallery__img"
                  @click="gotoProfile(match.data.attributes.slug)"
                />
              </div>
              <div
                v-else
                class="user-img-card"
                :style="{
                  backgroundImage: `url(${match.data.attributes.profile_photo_thumb_url})`,
                }"
                :class="
                  match.data.attributes.profile_photo_thumb_url
                    ? 'user-img-back'
                    : null
                "
              >
                <img
                  :src="
                    match.data.attributes.profile_photo_thumb_url ||
                    '/groom.png'
                  "
                  class="gallery__img user-img"
                  style="z-index: 0"
                  @click="gotoProfile(match.data.attributes.slug)"
                />
              </div>
              <p class="p-col-12 p-mb-3 c-tc-title">
                {{ match.data.attributes.first_name }} <br />
                <small class="c-tc-small">
                  {{
                    ageHeight(
                      match.data.attributes.age,
                      match.data.attributes.height
                    )
                  }}
                </small>
              </p>
            </div>
          </div>
        </template>
      </DataView>
    </div>
  </div>
</template>

<script>
import { ageHeightMixin } from '@/mixins/ageHeightMixin.js';
export default {
  mixins: [ageHeightMixin],
  props: {
    matches: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      loadingArray: [{}, {}, {}, {}, {}, {}],
    };
  },
  methods: {
    gotoProfile(id) {
      this.$router.push(`/profile/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-img-back {
  border: 1px solid $borderColor;
  border-radius: 20px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.view-all {
  color: #716ac3;
  &:hover {
    color: $primaryColor;
    font-weight: 500;
  }
}
.dashboard {
  width: 98%;
  margin-top: 3px;
}
.card {
  width: 100%;
  justify-content: center;
  margin: 0.5rem;
}

.img-col {
  display: flex;
  justify-content: center;
}
.gallery {
  justify-content: center;
  display: flex;
  width: 100%;
  margin: 1rem auto;
  justify-content: center;
  padding-left: 0.8em;
}

.gallery__img {
  width: 100%;
  height: 100%;
  height: 12rem;
  max-width: 20rem;
  object-fit: contain;
  display: block;
  border: 1px solid $profileImageBorder;
  border-radius: 20px;
  cursor: pointer;
  backdrop-filter: blur(8px);
}
.user-img-card {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.025);
    height: unset;
    border-radius: 20px;
    box-shadow: 0px 3px #dfdfdf;
  }
}

.gallery__item {
  padding: 0.1rem;
  margin: 0.1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  max-height: 15rem;
  max-width: 20rem;
  width: 100%;
}

small {
  color: $labelColor;
}
</style>
