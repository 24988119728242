<template>
  <router-link :to="info.link">
    <div
      :class="`overview-box ${info.colorClass} ${
        info.link ? '' : 'default-cursor'
      }`"
    >
      <div class="p-col-12 info p-d-flex p-jc-start p-ai-center">
        <div class="p-col-3 p-p-0">
          <i :class="info.icon"></i>
        </div>
        <div class="p-col-9 p-p-0">
          <div class="box-status p-jc-start p-d-flex">
            {{ info.title }}
          </div>
          <div class="p-jc-start p-d-flex box-value">
            <span v-if="loading"
              ><dot-loader class="dot-loader align-self-center"
            /></span>
            <span v-else>{{ info.count }}</span>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
import DotLoader from '@/utilities/DotLoader.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    DotLoader,
  },
  props: ['info', 'loading'],
  computed: {
    ...mapGetters(['authUser']),
  },
};
</script>
<style lang="scss" scoped>
.gender-big-icon {
  font-size: 2.6rem;
}
.loader {
  height: 115px;
}
.info {
  padding: 1rem 1rem;
  height: 10rem;
  box-shadow: 0 5px 3px -1px rgba(0, 0, 0, 0.15);
  .box-status {
    font-size: 1.4rem;
    padding-top: 0.7rem;
    padding-right: 0.7rem;
    padding-left: 0.7rem;
    text-align: left;
  }
  .box-value {
    font-size: 2.5rem;
    padding: 0.7rem;
  }
  .dot-loader {
    margin: unset;
    left: unset;
    margin-top: 6px;
  }
}
.overview-box {
  transition: all 0.3s;
}
.overview-box:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  cursor: pointer;
}
i {
  font-size: 1.8rem;
  opacity: 0.6;
}
span {
  height: 45px;
}
.default-cursor {
  cursor: default !important;
}
</style>
