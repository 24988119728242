<template>
  <div>
    <highcharts class="p-mx-2" :options="chartOptions" />
    <div class="p-d-flex p-flex-wrap">
      <highcharts
        class="p-pt-5 p-col-12 p-md-6 p-lg-6 p-pb-0 highchart-user"
        :options="pieChartOptions"
      />
      <highcharts
        class="p-pt-5 p-col-12 p-md-6 p-lg-6 p-pb-0"
        :options="adminChartOptions"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    approvalStats: {
      type: Array,
      default: () => {
        return [];
      },
    },
    malesCount: {
      type: Number,
      default: 0,
    },
    femalesCount: {
      type: Number,
      default: 0,
    },
    malesAge: {
      type: Array,
      default: () => {
        return [];
      },
    },
    femalesAge: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      colors: ['#FF6198', '#4d4d4d'],
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: 'column',
        },
        colors: this.colors,
        title: {
          text: 'Nowraans and Nowrins grouped by age',
        },
        xAxis: {
          categories: [18, 19, 20, 21, 22],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Count',
          },
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">Age:{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          column: {
            pointPadding: 0.1,
            borderWidth: 0,
          },
        },
        series: [
          {
            name: 'Nowraans',
            data: this.malesAge,
          },
          {
            name: 'Nowrins',
            data: this.femalesAge,
          },
        ],
      };
    },
    pieChartOptions() {
      return {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
        },
        title: {
          text: 'Nowraans vs Nowrins',
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y}</b>',
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            colors: this.colors,
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.y}',
              connectorColor: 'silver',
            },
          },
        },
        series: [
          {
            name: 'Count',
            data: [
              { name: 'Nowraans', y: this.malesCount },
              { name: 'Nowrins', y: this.femalesCount },
            ],
          },
        ],
      };
    },
    adminChartOptions() {
      return {
        chart: {
          type: 'column',
        },
        colors: this.colors,
        title: {
          text: 'Admins approval split',
        },
        xAxis: {
          type: 'category',
          labels: {
            style: {
              fontSize: '13px',
              fontFamily: 'Sarabun, sans-serif',
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Approval count (users)',
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          pointFormat: 'Approvals: <b>{point.y}</b>',
          useHTML: true,
        },
        series: [
          {
            data: this.approvalStats,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss">
.highcharts-container {
  border: 1px solid $borderColor;
  transition: all 0.3s;
  &:hover {
    box-shadow: 0 5px 3px -1px rgba(0, 0, 0, 0.15);
  }
  .highcharts-background {
    border: 1px solid $borderColor;
  }
  .highcharts-root {
    padding-top: 5px;
  }
}
@media screen and (min-width: 768px) {
  .highchart-user {
    padding-right: 1.5rem !important;
  }
}
</style>
