<template>
  <div class="p-grid dashboard">
    <div
      v-for="item of authUser.is_admin ? adminStats : userStats"
      :key="item.title"
      class="p-col-12 p-md-6 p-lg-3"
    >
      <stat-card :info="item" :loading="loading" />
    </div>
  </div>
</template>

<script>
import StatCard from '@/components/dashboard/StatCard.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    StatCard,
  },
  props: {
    details: Object,
    loading: Boolean,
  },
  data() {
    return {
      userStats: [
        {
          title: 'Shortlisted',
          colorClass: 'box-sales',
          link: '/matches/shortlisted',
          count: this.details.shortlisted_count,
          icon: 'fas fa-user-check',
        },
        {
          title: 'Invitation Sent',
          colorClass: 'box-views',
          link: '/mailbox/sentItems',
          count: this.details.sent_interests_count,
          icon: 'fab fa-telegram-plane',
        },
        {
          title: 'Invitation Received',
          colorClass: 'box-users',
          link: '/mailbox/invitationReceived',
          count: this.details.received_interests_count,
          icon: 'fas fa-envelope-open',
        },
        {
          title: 'New Matches',
          colorClass: 'box-checkins',
          link: '/matches/allmatches',
          count: this.details.new_matches_count,
          icon: 'fas fa-link',
        },
      ],
      adminStats: [
        {
          title: 'Active Users',
          colorClass: 'box-sales',
          link: '',
          count: this.details.active_user_count,
          icon: 'fas fa-user-check',
        },
        {
          title: 'Nowraans',
          colorClass: 'box-views',
          link: '/profiles/nowraans',
          count: this.details.male_count,
          icon: 'fas fa-user gender-big-icon',
        },
        {
          title: 'Nowrins',
          colorClass: 'box-users',
          link: '/profiles/nowrins',
          count: this.details.female_count,
          icon: 'far fa-user gender-big-icon',
        },
        {
          title: 'New Users',
          colorClass: 'box-checkins',
          link: '',
          count: this.details.last_month_new_users_count,
          icon: 'fas fa-users',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['authUser', 'canViewMatches', 'canViewMailBox']),
  },
  methods: {
    goToShortlisted() {
      if (this.canViewMatches) {
        this.$router.push('/matches/shortlisted');
      }
    },
    goToSentinterest() {
      if (this.canViewMailBox) {
        this.$router.push('/mailbox/sentItems');
      }
    },
    goToReceivedinterest() {
      if (this.canViewMailBox) {
        this.$router.push('/mailbox/invitationReceived');
      }
    },
    goToNewMatches() {
      if (this.canViewMatches) {
        this.$router.push('/matches/allmatches');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  width: 98%;
}
</style>
